.table td {
    text-align: center;
    justify-content: center;
}

.table th {
    text-align: center;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.separator {
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}