.custom-badge {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}
.custom-badge-risks {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid #e63131;
    min-width: 10px;
    line-height: 1;
    color: #000;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}

.custom-badge.custom-badge-yes {
    background-color: #e63131
}

.custom-badge.custom-badge-no {
    background-color: #60c960
}

.custom-badge.custom-badge-primary {
    background-color: orange
}

.custom-badge.custom-badge-outlined {
    background-color: transparent
}

.custom-badge.custom-badge-outlined.custom-badge-primary {
    border-color: orange;
    color: orange
}
.separator {
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}