.custom-dropdown {
    max-height: 80vh;
    overflow-y: auto;
}

.custom-dropdown-item {
    white-space: nowrap;
    word-break: normal;
    overflow: auto;
}

@media screen and (max-width: 991px) {
    .custom-dropdown-item {
        white-space: normal;
        word-break: break-word;
        overflow: auto;
    }
}

.full_width {
    position: relative !important;
    z-index: 5000;
}